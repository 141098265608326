import React, { useContext, useEffect } from "react";
import PageContext from "./PageContext";

const PageTitle: React.FC<{ title: string | null }> = ({ title }) => {
  const [, setPageTitle] = useContext(PageContext);
  useEffect(() => {
    setPageTitle && setPageTitle(title);
  }, [setPageTitle, title]);

  useEffect(() => {
    document.title = "Vibe3D" + (title ? ` | ${title}` : "");
  }, [title]);

  return null;
};

export default PageTitle;
