import PageTitle from "./PageTitle";
import { Box, FullBox, ContentBlock } from "@vibe3d/ui-lib/esm/layout";
import { Heading } from "@vibe3d/ui-lib/esm/components/Heading";
import { Text } from "@vibe3d/ui-lib/esm/components/Text";

export const LandingPageForUnauthorized = () => {
  return (
    <>
      <PageTitle title={null} />
      <FullBox align="center">
        <ContentBlock width="large">
          <Box px="medium">
            <Box py="medium">
              <Heading level="2">Vibe3D</Heading>
            </Box>
            <Box pb="small">
              <Text size="large">Visual in browser engine for 3d games</Text>
            </Box>
            <Text size="base">Node system to the rescue</Text>
          </Box>
        </ContentBlock>
      </FullBox>
    </>
  );
};
