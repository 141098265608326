import React from "react";

const Logo: React.VFC = () => (
  <svg fill="none" viewBox="0 0 200 200" role="img" aria-label="Vibe3D Logo">
    <circle cx="40" cy="40" r="30" fill="currentColor" />
    <circle cx="160" cy="40" r="30" fill="currentColor" />
    <circle cx="100" cy="160" r="30" fill="currentColor" />
    <path
      d="M40 40 L100 160 L165 40"
      stroke="currentColor"
      strokeWidth="20"
      fill="none"
    />
  </svg>
);

export default Logo;
