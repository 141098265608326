import React, { useContext } from "react";
import PageContext from "./PageContext";
import Logo from "./Logo";
import { Box, Columns, Column, FullBox } from "@vibe3d/ui-lib/esm/layout";
import { colorThemeVars } from "@vibe3d/ui-lib/esm/theme";
import { vs } from "@vibe3d/ui-lib/esm/utils";
import { TextLink } from "@vibe3d/ui-lib/esm/components/Link";
import { Text } from "@vibe3d/ui-lib/esm/components/Text";

import * as styles from "./Header.css";

const HeaderLink: React.FC<{ href: string }> = ({ href, children }) => {
  return (
    <FullBox alignY="center" asChild>
      <TextLink href={href} color="brand">
        <Text weight="semibold">{children}</Text>
      </TextLink>
    </FullBox>
  );
};

type Props = {
  auth: React.ReactNode;
};

const Header: React.VFC<Props> = ({ auth }) => {
  const [pageTitle] = useContext(PageContext);
  const style = vs([styles.logoColorVar, colorThemeVars.brand.logo]);
  return (
    <Box px="large" coloring="brand" boxHeight="full" style={style}>
      <Columns space="large" boxHeight="full">
        <Column width="content">
          <Columns space="small" boxHeight="full">
            <Column width="content">
              <HeaderLink href="/">
                <FullBox alignY="center" className={styles.logo}>
                  <Logo />
                </FullBox>
              </HeaderLink>
            </Column>
            <Column>
              <HeaderLink href="/">Vibe3D</HeaderLink>
            </Column>
            {pageTitle != null ? (
              <Column width="content">
                <Box display="flexRow" alignItems="center" boxHeight="full">
                  {`| ${pageTitle}`}
                </Box>
              </Column>
            ) : null}
          </Columns>
        </Column>
        <Column />
        <Column width="content">
          <HeaderLink href="/docs">Docs</HeaderLink>
        </Column>
        <Column width="content">
          <HeaderLink href="/playground">Playground</HeaderLink>
        </Column>
        {auth != null ? (
          <Column width="content">
            <FullBox alignY="center">{auth}</FullBox>
          </Column>
        ) : null}
      </Columns>
    </Box>
  );
};

export default Header;
