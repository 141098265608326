import "@vibe3d/ui-lib/esm/index.css";
import "@vibe3d/base-project/esm/index.css";
import "@vibe3d/game-project/esm/index.css";
import React, { Suspense, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import PageContext from "./site/PageContext";
import Layout from "./site/Layout";
import NotFound from "./site/NotFound";
import { FullBox } from "@vibe3d/ui-lib/esm/layout/FullBox";
import { FullSizeSpinner } from "@vibe3d/ui-lib/esm/icons/SpinnerIcon";
import {
  LinkComponentContext,
  makeLinkComponent,
} from "@vibe3d/ui-lib/esm/components/Link";
import { ErrorBoundary } from "react-error-boundary";
import Header from "./site/Header";
import { LandingPageForUnauthorized } from "./site/LandingPageForUnauthorized";

const Docs = React.lazy(() => import("./site/Docs"));
const Playground = React.lazy(() => import("./site/Playground"));

const LinkComponent = makeLinkComponent(({ href, ...props }, ref) => (
  <Link to={href} ref={ref} {...props} />
));

const App: React.FC = () => {
  const [pageTitle, setPageTitle] = useState<string | null>(null);

  return (
    <LinkComponentContext.Provider value={LinkComponent}>
      <PageContext.Provider value={[pageTitle, setPageTitle]}>
        <Router>
          <Layout header={<Header auth={null} />}>
            <ErrorBoundary
              fallback={
                <FullBox align="center" alignY="center">
                  Error
                </FullBox>
              }
            >
              <Suspense fallback={<FullSizeSpinner />}>
                <Switch>
                  <Route exact path="/">
                    <LandingPageForUnauthorized />
                  </Route>
                  <Route path="/docs">
                    <Docs />
                  </Route>
                  <Route exact path="/playground">
                    <Playground />
                  </Route>
                  <Route>
                    <NotFound />
                  </Route>
                </Switch>
              </Suspense>
            </ErrorBoundary>
          </Layout>
        </Router>
      </PageContext.Provider>
    </LinkComponentContext.Provider>
  );
};

export default App;
