import React from "react";
import { Box } from "@vibe3d/ui-lib/esm/layout";
import { RootBox, ThemeProvider } from "@vibe3d/ui-lib/esm/theme";

type Props = {
  header: React.ReactNode;
};

const Layout: React.FC<Props> = ({ children, header }) => {
  return (
    <ThemeProvider color="light" pointer="system">
      <RootBox display="flexColumn" boxHeight="full" overflowY="hidden">
        <Box boxMinHeight="3rem" flexShrink={0}>
          {header}
        </Box>
        <Box flexGrow overflowY="hidden">
          {children}
        </Box>
      </RootBox>
    </ThemeProvider>
  );
};

export default Layout;
