import React from "react";
import { Box } from "@vibe3d/ui-lib/esm/layout";
import { Text } from "@vibe3d/ui-lib/esm/components/Text";

const NotFound: React.FC = () => (
  <Box
    display="flexRow"
    alignItems="center"
    justifyContent="center"
    p="large"
    boxHeight="full"
  >
    <Text size="xl">404</Text>
  </Box>
);

export default NotFound;
